export const FirebaseConfig = {
	"projectId": "postit-dc1d5",
	"appId": "1:59731017220:web:9dfa567521e88bc46cbf51",
	"databaseURL": "https://postit-dc1d5-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "postit-dc1d5.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyAZaNxseeI1z2IBc73ySqZuq8wourAB36s",
	"authDomain": "postit-dc1d5.firebaseapp.com",
	"messagingSenderId": "59731017220",
	"measurementId": "G-CY25DFQ4BB"
};